import { Text, types, useAdminContext } from "react-bricks/frontend"

import React from "react"
import { TypographyElement } from "../Typography"

export interface HealthClaimProps {
    id: string
    index: number
    text?: TypographyElement[]
}

const HealthClaim: types.Brick<HealthClaimProps> = ({ index, text, id }) => {
    const { isAdmin } = useAdminContext()

    return (
        <div key={index} className="grid grid-cols-[auto_1fr]">
            <span className="text-xs md:text-sm">{id})&nbsp;</span>
            {isAdmin ? (
                <Text
                    propName="text"
                    renderBlock={({ children }) => <p className="text-xs md:text-sm">{children}</p>}
                    placeholder="Lorem ipsum dolor sit amet"
                />
            ) : text ? (
                <p className="text-xs md:text-sm">{text[0].children.map(c => c.text)}</p>
            ) : null}
        </div>
    )
}

HealthClaim.schema = {
    name: "HealthClaim",
    label: "Health Claim",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "id",
            label: "ID",
            type: types.SideEditPropType.Text,
        },
    ],
}

export default HealthClaim
