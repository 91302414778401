import "./base.css"

import RadioStyles from "./radio.module.css"
import React from "react"
import WrapWithError from "./wrap-with-error"
import classNames from "classnames"

const Radio = props => {
    return (
        <div
            className={classNames(RadioStyles.block, {
                [RadioStyles.flex]: props.flex,
                [RadioStyles.error]: props.error?.[props.register.name],
            })}
        >
            {props.label && (
                <div className={RadioStyles.label}>
                    {props.label}
                    {props.required && <span style={{ color: "#d24d57" }}> *</span>}
                </div>
            )}
            <WrapWithError error={props.error?.[props.register.name]}>
                {props.options.map(option => (
                    <div className={RadioStyles.radio} key={`${props.register.name}-${option.value}`}>
                        <input
                            {...props.register}
                            type="radio"
                            id={`${props.register.name}-${option.value}`}
                            value={option.value}
                            className="hiddenInput"
                        />
                        <label htmlFor={`${props.register.name}-${option.value}`}>{option.label}</label>
                        {option.extra && <div className={RadioStyles.extra}>{option.extra}</div>}
                    </div>
                ))}
            </WrapWithError>
        </div>
    )
}

export default Radio
