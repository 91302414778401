import { A } from "../../utils/router"
import Breadcrumbs from "../universal/breadcrumbs"
import Cart from "../aside/cart"
import HeaderStyles from "./header.module.css"
import { Image } from "../universal/image"
import LocalesMenu from "../menu/locales"
import Popper from "../universal/popper"
import React from "react"
import Sidebar from "../universal/sidebar"
import TopLevel from "./top-level"
import classNames from "classnames"
import { useAuthState } from "../../context/auth"
import { useClientState } from "../../context/client"
import { useLocalesState } from "../../context/locales"
import { usePopperState } from "../../context/popper"
import { useQueryCart } from "../../hooks/cart/use-query-cart"
import { useScrollPosition } from "../../hooks/use-scroll-position"
import { useTranslation } from "react-i18next"
import { useUrlSearchParams } from "../../hooks/use-url-search-params"

const Header = props => {
    const { t } = useTranslation("shared")

    const { isCustomer } = useAuthState()
    const { overlayOpen } = useLocalesState()
    const { showPopper } = usePopperState()

    const [params] = useUrlSearchParams()
    const hasPartnerUrlInside = params?.return_url?.includes("partner")

    const { cart_id, cart_access_token } = useClientState()

    const { data: cartData } = useQueryCart(
        { enabled: !!(props.configuration.cart && cart_id && cart_access_token) },
        { background: true }
    )
    const amount = cartData?.products.reduce((a, b) => a + b.quantity, 0) || 0

    const { scrollTop } = useScrollPosition(props.scrollContainer)

    return (
        <>
            {props.configuration.topLevel && (
                <TopLevel
                    customContact={props.configuration.customContact}
                    contact={props.configuration.topLevelContact}
                />
            )}
            <header
                className={classNames(HeaderStyles.header, {
                    [HeaderStyles.fixed]: props.configuration.fixed,
                    [HeaderStyles.noShadow]: props.configuration.noShadow && scrollTop <= 0,
                    [HeaderStyles.light]: props.configuration.light && !overlayOpen && scrollTop <= 0,
                    [HeaderStyles.fixedColor]: !props.configuration.fixed || scrollTop > 0 || overlayOpen,
                    [HeaderStyles.fixedColorPopper]: showPopper,
                })}
                id="header"
            >
                <div className={HeaderStyles.container}>
                    <div className={HeaderStyles.left}>
                        {typeof props.menu === "function"
                            ? props.menu(props.configuration.light && !overlayOpen && scrollTop <= 0)
                            : props.menu}
                        {props.configuration.customTitle && !props.configuration.customTitleLink && (
                            <span className={HeaderStyles.title}>{props.configuration.customTitle}</span>
                        )}
                        {props.configuration.customTitleLink && props.configuration.customTitle && (
                            <A href={props.configuration.customTitleLink} className={HeaderStyles.titleLink}>
                                <i className="lavita-icon"></i>
                                <span>{props.configuration.customTitle}</span>
                            </A>
                        )}
                        {props.configuration.breadcrumbs && props.breadcrumbs && (
                            <div className={HeaderStyles.breadcrumbs}>
                                <Breadcrumbs breadcrumbs={props.breadcrumbs} />
                            </div>
                        )}
                    </div>
                    {props.configuration.customLogo || (
                        <A href={props.configuration.logoLink} className={HeaderStyles.logo}>
                            <Image
                                url="assets"
                                path="/logo/LaVita_Logo_2020.png"
                                srcSet={[
                                    { w: 80 },
                                    { w: 119 },
                                    { w: 135, default: true },
                                    { w: 160 },
                                    { w: 238 },
                                    { w: 270 },
                                ]}
                                sizes="(max-width: 767px) 80px, (max-width: 1365px) 119px, 135px"
                            />
                        </A>
                    )}
                    <div className={HeaderStyles.right}>
                        {props.configuration.shop && (
                            <A href={process.env.SHOP_URL} className={classNames(HeaderStyles.link, HeaderStyles.shop)}>
                                <span>{t("header_shop")}</span>
                            </A>
                        )}
                        {props.configuration.account && (
                            <A href={process.env.ACCOUNT_URL} className={HeaderStyles.link} id={props.id}>
                                <i
                                    className={classNames("lavita-icon", {
                                        [HeaderStyles.loginIdentifier]: isCustomer,
                                    })}
                                >
                                    
                                </i>
                            </A>
                        )}
                        {props.configuration.cart && (
                            <>
                                {amount === 0 ? (
                                    <A href={process.env.SHOP_URL} className={HeaderStyles.link}>
                                        <i className="lavita-icon"></i>
                                    </A>
                                ) : (
                                    <Popper
                                        modifiers={{ name: "offset", options: { offset: [28, 27] } }}
                                        width={320}
                                        customInput={({ ref, onClick }) => (
                                            <button
                                                type="button"
                                                ref={ref}
                                                onClick={onClick}
                                                className={HeaderStyles.button}
                                            >
                                                <i className="lavita-icon">
                                                    
                                                    {amount !== 0 && <div className={HeaderStyles.badge}>{amount}</div>}
                                                </i>
                                            </button>
                                        )}
                                    >
                                        {closePopper => <Cart closePopper={closePopper} />}
                                    </Popper>
                                )}
                            </>
                        )}
                        {props.configuration.search && (
                            <Sidebar
                                customInput={({ ref, onClick }) => (
                                    <button type="button" ref={ref} onClick={onClick} className={HeaderStyles.link}>
                                        <i className="lavita-icon"></i>
                                    </button>
                                )}
                            >
                                {closeSidebar => props.configuration.search(closeSidebar)}
                            </Sidebar>
                        )}
                        {!hasPartnerUrlInside && props.configuration.language && (
                            <LocalesMenu
                                light={props.configuration.light && !overlayOpen && scrollTop <= 0}
                                translations={props.translations}
                            />
                        )}
                    </div>
                </div>
            </header>
        </>
    )
}

Header.defaultProps = {
    configuration: {
        account: true,
        cart: true,
        shop: true,
        language: true,
        topLevel: false,
        hamburger: true,
        search: false,
        active: "",
        fixed: false,
        light: false,
        logoLink: process.env.WEB_URL,
        customTitle: false,
        customTitleLink: false,
        customLogo: undefined,
        customContact: null,
        contact: true,
    },
}

export default Header
