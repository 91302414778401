import List, { ListItem } from "./product/pdp/List"
import PDPIconTextGrid, { PDPIconTextGridItem } from "./product/pdp/IconTextGrid"

import BasicText from "./text/BasicText"
import ButtonBrick from "./button/Button"
import CenterText from "./text/CenterText"
import ColumnCustomProduct from "./product/ColumnCustomProduct"
import ColumnProduct from "./product/ColumnProduct"
import ColumnQuote from "./quote/ColumnQuote"
import DecorativeCircle from "./decorative/DecorativeCircle"
import DecorativeText from "./decorative/DecorativeText"
import Divider from "./decorative/Divider"
import FAQ from "./faq/FAQ"
import FAQs from "./faq/FAQs"
import FixedThreeColumnProducts from "./product/FixedThreeColumnProducts"
import Form from "./form/form"
import FullWidthImageBreak from "./image/FullWidthImageBreak"
import FullWidthVideoBreak from "./video/FullWidthVideoBreak"
import GreenText from "./product/pdp/GreenText"
import GridIconText from "./icon-text/GridIconText"
import GridIconTextImage from "./icon-text/GridIconTextImage"
import HTML from "./html/html"
import HeadlineCircleText from "./text/HeadlineCircleText"
import HealthClaim from "./healthclaims/HealthClaim"
import Hero from "./hero/Hero"
import HeroImageBox from "./hero/HeroImageBox"
import IconText from "./icon-text/IconText"
import IconTextGrid from "./icon-text/IconTextGrid"
import IconTextList from "./product/IconTextList"
import IconTextShadowCard from "./icon-text/IconTextShadowCard"
import IconTextSlider from "./icon-text/IconTextSlider"
import IconTwoColumnsText from "./icon-text/IconTwoColumnsText"
import IconTwoColumnsTextGrid from "./icon-text/IconTwoColumsTextGrid"
import ImageButton from "./button/ImageButton"
import ImageText from "./image-text/three-column/ImageText"
import ImageTextColumn from "./ImageTextColumn/ImageTextColumn"
import ImageTextColumnItem from "./ImageTextColumn/ImageTextColumnItem"
import ImageTextSlider from "./image-text/slider/ImageTextSlider"
import ImageTextSliderItem from "./image-text/slider/ImageText"
import InfoBoxes from "./info-boxes/InfoBoxes"
import Intro from "./hero/Intro"
import MasterColumnIconText from "./icon-text/MasterColumnIconText"
import MasterColumnQuote from "./quote/MasterColumnQuote"
import MasterImageTextCircle from "./image-text/MasterImageTextCircle"
import MasterTeaser from "./teaser/MasterTeaser"
import MatrixItem from "./product/matrix/Item"
import MatrixItemRow from "./product/matrix/Row"
import NutritionTable from "./table/NutritionTable"
import PDP from "./product/PDP"
import PDPImage from "./product/pdp/Image"
import ProductCustom from "./product/ProductCustom"
import ProductMatrix from "./product/Matrix"
import ProductRepeaterImage from "./product/ProductRepeaterImage"
import ProductTeaser from "./teaser/ProductTeaser"
import ProductTeaserItem from "./teaser/ProductTeaserItem"
import QuoteBrick from "./quote/Quote"
import QuoteText from "./quote/QuoteText"
import QuoteTextSlider from "./quote/QuoteTextSlider"
import ShowMore from "./show-more/ShowMore"
import SingleProduct from "./product/TabProduct"
import Smallprint from "./text/Smallprint"
import SmallprintItem from "./text/SmallprintItem"
import StarsText from "./icon-text/StarsText"
import StickyHealthClaims from "./healthclaims/StickyHealthClaims"
import StickyProductBox from "./product/StickyProductBox"
import Stripe from "./stripe/Stripe"
import StripeIconText from "./stripe/StripeIconText"
import SubscriptionTeaser from "./teaser/SubscriptionTeaser"
import TabContent from "./tab/TabContent"
import TabContentItem from "./tab/TabContentItem"
import TabbedPDP from "./product/TabbedPDP"
import TabbedProducts from "./product/TabbedProducts"
import Table from "./table/Table"
import TableCell from "./table/TableCell"
import TableRow from "./table/TableRow"
import Text from "./text/Text"
import Text60Image40 from "./components/text-image/text-60-image-40"
import TextCenterImage from "./image-text/TextCenterImage"
import TextLinkBrick from "./text/TextLink"
import TextLinksShadowCard from "./text/TextLinksShadowCard"
import ThreeColumnBigIconText from "./icon-text/ThreeColumnBigIconText"
import ThreeColumnBigIconTextItem from "./icon-text/BigIconText"
import ThreeColumnCustomProducts from "./product/ThreeColumnCustomProducts"
import ThreeColumnImageText from "./image-text/three-column/ThreeColumnImageText"
import ThreeColumnProducts from "./product/ThreeColumnProducts"
import Trust from "./product/pdp/Trust"
import TrustReview from "./stripe/TrustReview"
import TrustReviewsStripe from "./stripe/TrustReviewsStripe"
import TrustStripe from "./stripe/TrustStripe"
import TwoColumnImageText from "./image-text/two-column/TwoColumnImageText"
import TwoColumnImageTextItem from "./image-text/two-column/ImageText"
import VideoPlayer from "./video/VideoPlayer"
import WhatsappButton from "./whatsapp/WhatsappButton"

const bricks = [
    BasicText,
    ButtonBrick,
    CenterText,
    ColumnCustomProduct,
    ColumnProduct,
    ColumnQuote,
    DecorativeCircle,
    DecorativeText,
    Divider,
    FAQ,
    FAQs,
    FixedThreeColumnProducts,
    Form,
    FullWidthImageBreak,
    FullWidthVideoBreak,
    GreenText,
    GridIconText,
    GridIconTextImage,
    HTML,
    HeadlineCircleText,
    HealthClaim,
    Hero,
    HeroImageBox,
    IconText,
    IconTextGrid,
    PDPIconTextGrid,
    ProductTeaser,
    ProductTeaserItem,
    PDPIconTextGridItem,
    IconTextList,
    IconTextShadowCard,
    IconTextSlider,
    IconTwoColumnsTextGrid,
    IconTwoColumnsText,
    ImageButton,
    ImageText,
    ImageTextSlider,
    ImageTextSliderItem,
    InfoBoxes,
    Intro,
    List,
    ListItem,
    MasterColumnIconText,
    MasterColumnQuote,
    MasterImageTextCircle,
    MasterTeaser,
    MatrixItem,
    MatrixItemRow,
    NutritionTable,
    PDP,
    PDPImage,
    ProductCustom,
    ProductMatrix,
    ProductRepeaterImage,
    QuoteBrick,
    QuoteText,
    QuoteTextSlider,
    ShowMore,
    SingleProduct,
    Smallprint,
    SmallprintItem,
    StarsText,
    StickyHealthClaims,
    StickyProductBox,
    Stripe,
    StripeIconText,
    SubscriptionTeaser,
    TabContent,
    TabContentItem,
    TabbedPDP,
    TabbedProducts,
    Table,
    TableCell,
    TableRow,
    Text,
    Text60Image40,
    TextCenterImage,
    TextLinkBrick,
    TextLinksShadowCard,
    ThreeColumnBigIconText,
    ThreeColumnBigIconTextItem,
    ThreeColumnImageText,
    ThreeColumnProducts,
    ThreeColumnCustomProducts,
    Trust,
    TrustReview,
    TrustReviewsStripe,
    TrustStripe,
    TwoColumnImageText,
    TwoColumnImageTextItem,
    VideoPlayer,
    WhatsappButton,
    ImageTextColumn,
    ImageTextColumnItem,
]

export default bricks
