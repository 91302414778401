import "./base.css"

import React, { useEffect } from "react"

import WrapWithError from "./wrap-with-error"
import classNames from "classnames"
import { useController } from "react-hook-form"
import { useState } from "react"
import { useTranslation } from "react-i18next"

const Input = ({
    required,
    name,
    control,
    rules,
    defaultValue,
    shouldUnregister,
    transform,
    length,
    showLengthInfo,
    type,
    autocomplete,
    width,
    height,
    autoSize,
    label,
    onChange: onChangeProp,
    className,
    optional,
}) => {
    const {
        field: { onChange, onBlur, value, ref, ...rest },
        fieldState: { error, isTouched },
    } = useController({
        name,
        control,
        rules,
        defaultValue,
        shouldUnregister,
    })

    const { t } = useTranslation("shared")

    const info = length &&
        showLengthInfo && {
            message: t("remaining_chars", { count: length - value.length }),
        }

    const [showPassword, setShowPassword] = useState(false)

    // dirty fix for autofilled inputs from chrome, works only if user interacted with window
    useEffect(() => {
        if (!ref.current) return

        const interval = setInterval(() => {
            if (ref.current.value) {
                onChange(transform ? transform.output(ref.current.value) : ref.current.value)
                clearInterval(interval)
            }
        }, 100)
        return () => clearInterval(interval)
    }, [ref, onChange, transform])

    return (
        <div
            className={classNames("inputBlock", {
                error: error,
                wasInFocus: (isTouched && value) || value,
                [className]: className,
            })}
        >
            <WrapWithError error={error} info={info} optional={optional}>
                <input
                    type={type !== "password" ? type : showPassword ? "text" : "password"}
                    name={name}
                    id={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={e => {
                        onChange(transform ? transform.output(e.target.value) : e)
                        onChangeProp && onChangeProp(e.target.value)
                    }}
                    className="input"
                    autoComplete={autocomplete}
                    maxLength={length}
                    ref={ref}
                    style={{ width: width, height: height }}
                    size={autoSize && (length || value?.length)}
                    {...rest}
                />
                <label className="label" htmlFor={name}>
                    {label} {required && <span style={{ color: "#d24d57" }}>*</span>}
                </label>
                {type === "password" && value && (
                    <button
                        type="button"
                        onMouseDown={() => setShowPassword(true)}
                        onMouseLeave={() => setShowPassword(false)}
                        onMouseUp={() => setShowPassword(false)}
                        onTouchStart={() => setShowPassword(true)}
                        onTouchMove={() => setShowPassword(false)}
                        onTouchEnd={() => setShowPassword(false)}
                        className="password"
                    >
                        <i className="lavita-icon"></i>
                    </button>
                )}
            </WrapWithError>
        </div>
    )
}

export default Input
