import HealthClaim, { HealthClaimProps } from "./HealthClaim"
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Repeater, Text, types, useAdminContext } from "react-bricks/frontend"

import { Container } from "../components/layout"
import { Icon } from "../components/icon"
import { Icons } from "../../types"
import classNames from "classnames"
import { useUrlSearchParams } from "../../shared/hooks/use-url-search-params"

export interface StickyHealthClaimsProps {
    repeater?: {
        id: string
        props: HealthClaimProps
        type: "HealthClaim"
    }[]
}

const StickyHealthClaims: types.Brick<StickyHealthClaimsProps> = ({ repeater }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [scrollTop, setScrollTop] = useState(0)
    const [offset, setOffset] = useState(0)
    const [closed, setClosed] = useState(false)

    useLayoutEffect(() => {
        const currentRef = ref.current
        if (!currentRef) return

        const handleWindowScroll = () => {
            setScrollTop(document.documentElement.scrollTop)
        }
        const handleResize = () => {
            setOffset(currentRef.offsetTop)
        }
        handleWindowScroll()
        handleResize()
        window.addEventListener("scroll", handleWindowScroll)
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("scroll", handleWindowScroll)
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const { isAdmin } = useAdminContext()

    useEffect(() => {
        if (closed) return

        setClosed(scrollTop > offset && !isAdmin)
    }, [scrollTop, offset, isAdmin, closed])

    const [params] = useUrlSearchParams()

    if (!params.hc && !isAdmin) return null

    const healthClaims = useMemo(() => {
        if (isAdmin) return repeater
        return repeater?.filter(r => String(params.hc)?.includes(r.props.id))
    }, [params.hc, repeater, isAdmin])

    if ((!healthClaims || healthClaims.length === 0) && !isAdmin) return null

    return (
        <div ref={ref}>
            <div
                className={classNames(
                    "bottom-0 z-7 w-full bg-white py-5 shadow-[0_-6px_8px_0_rgba(0,0,0,0.1)] lg:py-7",
                    {
                        hidden: closed,
                        fixed: !isAdmin,
                    }
                )}
            >
                <Container>
                    <div className="flex items-start justify-between">
                        <div className="max-h-20 w-full max-w-[1152px] overflow-auto sm:max-h-none">
                            <Text
                                propName="headline"
                                renderBlock={({ children }) => (
                                    <p className="text-xs font-bold md:text-sm">{children}</p>
                                )}
                                placeholder="Lorem ipsum dolor sit amet"
                            />
                            {isAdmin ? (
                                <Repeater propName="repeater" />
                            ) : (
                                healthClaims?.map((r, i) => <HealthClaim key={r.id} {...r.props} index={i} />)
                            )}
                        </div>
                        <button type="button" className="ml-1 sm:ml-6" onClick={() => setClosed(true)}>
                            <Icon icon={Icons.cross_thin} className="text-base leading-none sm:text-xl" />
                        </button>
                    </div>
                </Container>
            </div>
        </div>
    )
}

StickyHealthClaims.schema = {
    name: "StickyHealthClaims",
    label: "Sticky Health Claims",
    repeaterItems: [
        {
            name: "repeater",
            items: [
                {
                    type: "HealthClaim",
                    label: "Health Claim",
                    min: 1,
                },
            ],
        },
    ],
}

export default StickyHealthClaims
