import { Text, types } from "react-bricks/frontend"

import { EqualHeightElement } from "react-equal-height/clean"
import React from "react"

export interface MatrixItemRowProps {
    badge?: boolean
    index?: number
    row?: number
}

const MatrixItemRow: types.Brick<MatrixItemRowProps> = ({ index, badge, row }) => {
    return (
        <div key={index} className="border-b border-border-tide py-3">
            <EqualHeightElement name={"row-" + index + "-" + row}>
                <Text
                    propName="label"
                    renderBlock={({ children }) => (
                        <p className="text-box mb-3 text-sm font-semibold text-light-grey">{children}</p>
                    )}
                    placeholder="Lorem ipsum"
                />
                <div className="flex gap-3 sm:gap-1.5 md:gap-5">
                    <div className="flex-grow">
                        <Text
                            propName="text"
                            renderBlock={({ children }) => (
                                <p className="text-box text-base font-semibold md:text-md">{children}</p>
                            )}
                            placeholder="Lorem ipsum"
                        />
                    </div>
                    {badge && (
                        <div className="flex-shrink-0">
                            <Text
                                propName="badgeText"
                                renderBlock={({ children }) => (
                                    <p className="text-box rounded-full bg-orange px-3.5 py-1.5 text-sm font-semibold text-white sm:text-xs md:text-sm">
                                        {children}
                                    </p>
                                )}
                                placeholder="Lorem ipsum"
                            />
                        </div>
                    )}
                </div>
            </EqualHeightElement>
        </div>
    )
}

MatrixItemRow.schema = {
    name: "MatrixItemRow",
    label: "MatrixItemRow",
    hideFromAddMenu: true,
    sideEditProps: [
        {
            name: "badge",
            label: "Badge",
            type: types.SideEditPropType.Boolean,
        },
    ],
    getDefaultProps: (): MatrixItemRowProps => ({
        badge: false,
    }),
}

export default MatrixItemRow
