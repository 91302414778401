import CartItem from "./cart-item"
import CartStyles from "./cart.module.css"
import IconAction from "../universal/icon-action"
import IconActionWrapper from "../universal/icon-action-wrapper"
import React from "react"
import { useAuthState } from "../../context/auth"
import { useQueryCart } from "../../hooks/cart/use-query-cart"
import { useTranslation } from "react-i18next"

const Cart = props => {
    const { t } = useTranslation("shared")
    const { isCustomer } = useAuthState()
    const { data: cartData } = useQueryCart()

    return (
        <section className={CartStyles.block}>
            <div className={CartStyles.items}>
                {cartData.products.map(p => (
                    <CartItem key={p.id} {...p} />
                ))}
            </div>
            <IconActionWrapper grid oneColumn>
                <IconAction
                    url={
                        isCustomer
                            ? `${process.env.SHOP_URL}/checkout`
                            : `${process.env.SHOP_URL}/login`
                    }
                    title={t("cart_overlay_checkout")}
                    button
                    id="cart_overlay_checkout"
                    withOnClick={props.closePopper}
                    fullWidth
                />
                <IconAction
                    url={`${process.env.SHOP_URL}/cart`}
                    title={t("cart_overlay_cart")}
                    icon="j"
                    button
                    white
                    id="cart_overlay_cart"
                    withOnClick={props.closePopper}
                    fullWidth
                />
            </IconActionWrapper>
        </section>
    )
}

export default Cart
